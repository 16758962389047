<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div>
      <div
        v-if="profileTitle"
        class="pl-5"
      >
        User Profile: {{ profileTitle }}
      </div>
    </div>

    <!-- ПРАВАЯ СТОРОНА -->
    <div

      class="d-flex align-center"
    >
      <v-tooltip

        bottom
      >
        <template #activator="{ on }">
          <v-btn

            color="primary"
            icon
            class="mr-2"
            :loading="loadingRefreshButton"
            :disabled="loadingRefreshButton"
            v-on="on"
            @click="updateExchangeRate"
          >
            <v-icon
              v-if="team > 0"
              size="18"
            >
              mdi-sync
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить exchange rate у всех подписок</span>
      </v-tooltip>
      <!-- ПОИСК -->
      <v-text-field
        :value="topbarSearch.searchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'UsersTopbar',
  data() {
    return {
      loadingRefreshButton: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'recurrentPayments/loading',
      profile: 'main/profile',
      team: 'recurrentPayments/team',
      profileTitle: 'recurrentPayments/profileTitle',
      pagination: 'users/pagination',
    }),
    ...mapFields('recurrentPayments', ['topbarSearch']),
  },

  methods: {
    updateExchangeRate() {
      this.loadingRefreshButton = true;
      this.api.post(`/admin/subscription/update/cloudpayments/${this.team}`).then(() => {
        this.$store.dispatch('main/alert', { color: 'success', message: 'Подписки были успешно изменены' });
      }).finally(() => {
        this.loadingRefreshButton = false;
      });
    },
    search(name) {
      this.topbarSearch.searchInput = name;
      setTimeout(async () => {
        if (name === this.topbarSearch.searchInput) {
          this.$store.dispatch('recurrentPayments/loadRecurrentPayments', {
            page: this.pagination.currentPage,
            itemsPerPage: this.pagination.perPage,
            query: this.topbarSearch.searchInput,
          });
        }
      }, 500);
    },
  },
};
</script>
