<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="payments.selected"
      :items="payments.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
    >
      <!-- ДЕЙСТВИЯ -->
      <template #[`item.actions`]="{item}">
        <cell-actions
          v-if="item.Status === 'Active' || item.Status === 'Pending'"
          :id="item.id"
        />
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import CellActions from './MainTableCellActions.vue';

export default {
  name: 'UsersMainTable',

  components: {
    CellActions,
  },

  data() {
    return {
      moment,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'recurrentPayments/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'recurrentPayments/pagination',
      profile: 'main/profile',
      topbarSearch: 'recurrentPayments/topbarSearch',
    }),
    ...mapFields('recurrentPayments', ['payments']),

    cols() {
      const cols = [];

      cols.push({ text: 'id', value: 'id' });
      cols.push({ text: 'Start', value: 'Start' });
      cols.push({ text: 'Last', value: 'Last' });
      cols.push({ text: 'Next', value: 'Next' });
      cols.push({ text: 'Plan', value: 'Plan' });
      cols.push({ text: 'Period', value: 'Period' });
      cols.push({ text: 'Account', value: 'Account' });
      cols.push({ text: 'Amount', value: 'Amount' });
      cols.push({ text: 'Status', value: 'Status' });
      cols.push({ text: 'Provider', value: 'Provider' });
      cols.push({ text: 'Payments', value: 'Payments' });
      cols.push({
        text: 'Actions', value: 'actions', width: 250, align: 'end', sortable: false,
      });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {

    updateFooterOptions(options) {
      this.$store.dispatch('users/loadUsers', {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        query: this.topbarSearch.searchInput,
      });
    },

  },
};
</script>
