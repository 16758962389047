<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import MainTable from '../components/recurrentPayments/recurrentPaymentsMainTable.vue';
import Topbar from '../components/recurrentPayments/recurrentPaymentsTopbar.vue';

export default {
  name: 'Subscriptions',
  components: { MainTable, Topbar },
};
</script>

<style scoped>

</style>
