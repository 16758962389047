<template>
  <div>
    <!-- ПОЛЬЗОВАТЕЛИ  -->
    <v-tooltip

      bottom
    >
      <template #activator="{ on }">
        <v-btn

          color="primary"
          icon
          class="mr-2"
          :loading="loaderCancel"
          :disabled="loaderCancel"
          v-on="on"
          @click="cancelSubscription"
        >
          <v-icon
            size="18"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </template>
      <span> Отменить подписку</span>
    </v-tooltip>
    <v-tooltip

      bottom
    >
      <template #activator="{ on }">
        <v-btn

          color="primary"
          icon
          class="mr-2"
          :loading="loaderUpdate"
          :disabled="loaderUpdate"
          @click="updateSubscription"
          v-on="on"
        >
          <v-icon
            size="18"
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </template>
      <span> Обновить платеж</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clipboard from '../../mixins/clipboard';

export default {
  name: 'UsersMainTableCellActions',

  mixins: [clipboard],

  props: ['id'],

  computed: {
    ...mapGetters({
      profile: 'main/profile',
    }),
    ...mapFields('users', ['userTransactions', 'topbarSearch']),
  },
  data() {
    return {
      loaderCancel: false,
      loaderUpdate: false,
    };
  },
  methods: {
    cancelSubscription() {
      console.log('id', this.id);
      this.loaderCancel = true;

      this.api.delete(`/admin/subscription/cloudpayments/${this.id}`).finally(() => {
        this.loaderCancel = false;
      });
    },
    updateSubscription() {
      this.loaderCancel = true;

      this.api.patch(`/admin/subscription/cloudpayments/${this.id}`).finally(() => {
        this.loaderCancel = false;
      });
    },
  },
};
</script>
